import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useIntl } from "react-intl"
import { MobileNavbar } from "../../components/general/MobileNavbar/MobileNavbar"
import { Navbar } from "../../components/general/Navbar/Navbar"
import { Notifications } from "../../components/general/Notifications/Notifications"
import { Protected } from "../../components/general/Protected/Protected"
import { Seo } from "../../components/general/Seo/Seo"
import { GoogleKeysModal } from "../../components/indexation/GoogleKeysModal/GoogleKeysModal"
import { IndexNowCheckModal } from "../../components/indexation/IndexMeNowCheckModal/IndexNowCheckModal"
import { SupportUsModal } from "../../components/indexation/SupportUsModal/SupportUsModal"
import { UpdateSitemapModal } from "../../components/indexation/UpdateSitemapModal/UpdateSitemapModal"
import { Footer } from "../../components/marketing/Footer"
import { PremiumModal } from "../../components/payment/UpsellConfirmationModal/UpsellConfirmationModal"
import { Hero } from "../../components/roast/issues/Hero/Hero"
import { Issues } from "../../components/roast/issues/Issues/Issues"
import { Container } from "../../components/UI/Container"
import { IssueTypes } from "../../entities/IssueEntity"

export const ServerRoute: React.FC<RouteComponentProps> = () => {
  const intl = useIntl()
  return (
    <Protected>
      <Seo
        title={intl.formatMessage({
          id: `seo/indexation/title`,
        })}
        description={intl.formatMessage({
          id: `seo/indexation/description`,
        })}
        image="/og/en-banner.jpg"
        lang="en"
        langUrls={[
          {
            lang: "en",
            url: "/settings",
            isDefault: true,
          },
        ]}
      />
      <Navbar />

      <Container className="relative">
        <div className="mt-4" id="pagination-anchor-scroll"></div>
        <Hero type={IssueTypes.server} />
        <Issues type={IssueTypes.server} />
      </Container>

      <Footer />

      <MobileNavbar />
      <SupportUsModal />
      <UpdateSitemapModal />
      <PremiumModal />
      <GoogleKeysModal />
      <IndexNowCheckModal />
      <Notifications />
    </Protected>
  )
}
