import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useIntl } from "react-intl"
import { CreateWebsiteModal } from "../../components/general/CreateWebsiteModal/CreateWebsiteModal"
import { Navbar } from "../../components/general/Navbar/Navbar"
import { Notifications } from "../../components/general/Notifications/Notifications"
import { Protected } from "../../components/general/Protected/Protected"
import { Seo } from "../../components/general/Seo/Seo"
import { GoogleKeysModal } from "../../components/indexation/GoogleKeysModal/GoogleKeysModal"
import { IndexNowCheckModal } from "../../components/indexation/IndexMeNowCheckModal/IndexNowCheckModal"
import { Footer } from "../../components/marketing/Footer"
import { PremiumModal } from "../../components/payment/UpsellConfirmationModal/UpsellConfirmationModal"
import { WebsitesList } from "../../components/roast/dashboard/WebsitesList/WebsitesList"
import { Container } from "../../components/UI/Container"

export const DashboardRoute: React.FC<RouteComponentProps> = () => {
  const intl = useIntl()
  return (
    <Protected>
      <Seo
        title={intl.formatMessage({
          id: `seo/opportunities/title`,
        })}
        description={intl.formatMessage({
          id: `seo/opportunities/description`,
        })}
        image="/og/en-banner.jpg"
        lang="en"
        langUrls={[
          {
            lang: "en",
            url: "/opportunities",
            isDefault: true,
          },
        ]}
      />

      <div className="relative min-h-screen">
        <Navbar />

        <Container>
          <div className="mt-8" />

          <WebsitesList />

          <div className="relative"></div>
          <div className="mt-4" />
        </Container>
      </div>

      <IndexNowCheckModal />
      <GoogleKeysModal />
      <CreateWebsiteModal />
      <PremiumModal />
      <Footer />
      <Notifications />
    </Protected>
  )
}
